import createCacheRequest from '$commons/components/createCacheRequest'
import { useCookies } from '@vueuse/integrations/useCookies'
import { defineStore } from 'pinia'
import { computed } from 'vue'

export const useSessionStore = defineStore('session_agents', () => {
  const cookies = useCookies(['isSignIn'], { autoUpdateDependencies: true })
  const isSignIn = computed(() => !!cookies.get('isSignIn'))

  // TODO SignedInByAgent
  function markSignedIn() {
    cookies.set('isSignIn', '1', { path: '/' })
    cookies.set('SignedInByAgent', '1', { path: '/' })
  }

  function clear() {
    cookies.remove('isSignIn', { path: '/' })
    cookies.remove('SignedInByAgent', { path: '/' })
    cleanup()
  }

  const { request, cleanup } = createCacheRequest()

  return { clear, isSignIn, request, markSignedIn }
})
